<template>
  <section>
    <v-container>
      <v-row align="center">
        <v-col
          cols="12"
          md="6"
        >
          <Title
            class="pt-0"
            title="Deixe sua mensagem"
          />
          <v-form
            @submit.prevent="sendMessage"
            class="d-flex flex-column"
          >
            <v-text-field
              v-model="formData.name"
              placeholder="Nome"
              rounded
              filled
              dense
            />
            <v-text-field
              v-model="formData.email"
              placeholder="Email"
              type="email"
              rounded
              filled
              dense
            />
            <v-textarea
              v-model="formData.message"
              placeholder="Mensagem"
              rounded
              filled
              rows="8"
              auto-grow
            />
            <v-btn
              :width="$vuetify.breakpoint.mobile ? '' : '25%'"
              class="align-self-end"
              rounded
              color="primary"
              type="submit"
              :loading="loading"
            >
              Enviar
              <v-icon right>fas fa-paper-plane</v-icon>
            </v-btn>
          </v-form>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          order="first"
          cols="6"
        >
          <v-img
            src="@/assets/illustration04.svg"
            max-width="70%"
          />
        </v-col>
      </v-row>
      <v-row
        align="end"
        class="my-10"
      >
        <v-col
          cols="12"
          md="6"
        >
          <Title title="Fale Conosco" />
          <ul class="pl-0 mb-2">
            <li
              class="d-flex align-center py-1"
              v-for="(item, idx) in items"
              :key="idx"
            >
              <v-icon
                :small="!$vuetify.breakpoint.xl"
                class="mr-2"
                color="primary"
                >{{ item.icon }}</v-icon
              >
              <p
                class="text-xl-h6"
                style="max-width: 50%; margin: 0"
              >
                {{ item.text }}
              </p>
            </li>
          </ul>
          <div v-if="contact != null">
            <SocialButtons
              bg="secondary"
              color="white"
            />
          </div>
        </v-col>
        <v-col
          align="right"
          cols="12"
          md="6"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.232659484349!2d-46.512591785491566!3d-23.56008606742929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce675c57820719%3A0xafd5f40e2958a629!2sMagazine%20Brindes!5e0!3m2!1spt-BR!2sbr!4v1660325175959!5m2!1spt-BR!2sbr"
            width="100%"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {
    Title: () => import('@/components/web/layout/widgets/Title'),
    SocialButtons: () =>
      import('@/components/web/layout/widgets/SocialButtons'),
  },
  data: () => ({
    routes: [{ title: 'Contato', route: '/contact' }],
    items: [
      { text: '(11) 9.8578-0621', icon: 'fab fa-whatsapp' },
      { text: '(11) 2679-2007', icon: 'fas fa-phone-alt' },
      { text: 'vendas@magazinebrindes.com.br', icon: 'fas fa-envelope' },
      {
        text: 'Rua Costeira, 546 Comp. Jardim Arize - CEP: 03573-010',
        icon: 'fas fa-map-marked-alt',
      },
    ],
    contact: null,
    formData: {},
    loading: false,
  }),
  methods: {
    getContact() {
      this.$api
        .get(`contact/get/1`)
        .then((res) => {
          this.contact = res.data.data
          this.items[0].text = this.contact.wpp
          this.items[1].text = this.contact.phone_number
          this.items[2].text = this.contact.email
          this.items[3].text =
            this.contact.address +
            ', ' +
            this.contact.number +
            ', ' +
            this.contact.district +
            ', ' +
            this.contact.city +
            ', CEP:' +
            this.contact.cep
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async sendMessage() {
      this.loading = true
      await this.$api
        .post('contact/message/send', this.formData)
        .then((res) => {
          this.formData = {}
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: res.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch((err) => {
          console.log(err.response.data.error)
          let errorMessage = null
          if (err.response.data.error.name) {
            errorMessage = err.response.data.error.name[0]
          } else if (err.response.data.error.email) {
            errorMessage = err.response.data.error.email[0]
          } else if (err.response.data.error.message) {
            errorMessage = err.response.data.error.message[0]
          }
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: err.response.data.message,
            caption: errorMessage,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.$store.commit('setBreadcrumbs', this.routes)
    this.getContact()
  },
}
</script>
